<template>
  <section>
    {{ $options.name }}
    <p>TODO: setup view</p>
  </section>
</template>

<script>
export default {
  name: 'Notfound'
}
</script>

<style lang="scss">

</style>
